html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  height: 100vh;
  font-family: 'Roboto Mono', 'Noto Sans', sans-serif;
  background: #B3D5FF;
}

.App {
  text-align: center;
  display: grid;
  grid-template-rows: auto 1fr;
}

@media screen and (min-width: 768px) {
  .App {
    grid-template-rows: 10vh auto;
    max-height: 100vh;
    overflow: auto;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}